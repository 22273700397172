import { Component, OnInit, Input, AfterViewInit, Inject, PLATFORM_ID, Optional, HostListener } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatePipe, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { find, difference } from 'lodash-es';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { ProductService } from '../../../product/services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { LocationService } from '../../../home/services/location.service';
import { UtilsService } from '../../../core/services/utils.service';
import { UserService } from '../../services/user.service';
import { RenewNowModalComponent } from '../renew-now-modal/renew-now-modal.component';
import { SiteContentService } from '../../../core/services/siteContents';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { OneTimePaymentOracleComponent } from '../../../core/component/one-time-payment-oracle/one-time-payment-oracle.component';
import { ResyncEnrollmentService } from '../../services/resync-enrollment.service';
import { CustomerServiceValidationModalComponent } from '../customer-service-validation-modal/customer-service-validation-modal.component';

@Component({
  selector: 'hos-checkout-customer-accounts-activity',
  templateUrl: './checkout-customer-accounts-activity.component.html',
  styleUrls: ['./checkout-customer-accounts-activity.component.scss', '../my-account-services-redesign/my-account-services-redesign.component.scss'],
})
export class CheckoutMyAccountServicesComponent implements OnInit, AfterViewInit {
  @Input() enrolledServices: any;

  @Input() services: any;

  @Input() user: any;

  @Input() renewedEnrollments: any;

  termsLink: string;

  imageBaseUrl: string;

  baseUrlLoc: string;

  today: any;

  partnerName: any;

  locData: any[];

  websitePartnerName: String;

  statusReasonId: String = environment.statusReasonId;

  math = Math;

  marketing_code_id: any;

  billingData: any;

  taxData: any;

  siteContent: any;

  @Input() userDetailsWithAddress: any;

  siteNameParam: any;

  isbrowser = true;

  isserver = false;

  isDesktopView: any;

  showLoader = false;

  locationDetailsForOneTime: any;

  customer_service_number: any;

  @Input() locationCodeData: any;

  @Input() selectedEnrollAddIndex: any;

  claimsLineHeight: any;

  selectedEnrollAddIndexMOB: any = null;

  propertiesInfo: any;

  isTabletView: any;

  isHome: any;

  parentEnrolments: any = [];

  selectedAddIndex = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktopView = event.target.innerWidth > 1023;
    this.isTabletView = !!(event.target.innerWidth > 767 && event.target.innerWidth <= 1023);
  }

  constructor(
    private simpleModalService: SimpleModalService,
    private orderService: OrdersService,
    private datePipe: DatePipe,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private productService: ProductService,
    private crmProxyService: CrmProxyService,
    private utilsService: UtilsService,
    private siteContentService: SiteContentService,
    private locationService: LocationService,
    public globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    private route: ActivatedRoute,
    private resyncEnrollment: ResyncEnrollmentService,
  ) {
    this.websitePartnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    this.route.queryParams.subscribe(params => {
      this.isHome = params.home;
    });
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.isDesktopView = window.screen.width > 1023;
    this.isTabletView = !!(window.screen.width > 767 && window.screen.width <= 1023);
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s', 'UTC');
    const uniqueEnrollments = new Map();

    if (this.services && this.services.length === 0) {
      const alertData = {
        title: 'It looks like all of your coverage is up to date!',
        message: `If further assistance is needed, please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span> to verify your coverage.`,
        alertIcon: 'fa fa-info-circle red',
        cancelText: ' ',
        confirmText: 'Close',
        reloadPage: true,
      };
      this.showAlertModal(alertData);
    }
    else{
      this.services.forEach(enrollment => {
        const uniqueKey = `${enrollment.crmEnrollmentNumber}-${enrollment.crmEnrollmentGuid}`;

        // Only add to the Map if the unique key is not present
        if (!uniqueEnrollments.has(uniqueKey)) {
            uniqueEnrollments.set(uniqueKey, enrollment);
        }
    });
    this.services = Array.from(uniqueEnrollments.values());
    }
    if (this.isbrowser) {
      if (window.location.pathname.includes('enrollment-update-renewal')) {
        this.sortServicesBasedOnQueryParams('renewal');
      } else if (window.location.pathname.includes('enrollment-update-reactivate')) {
        this.sortServicesBasedOnQueryParams('reactivate');
      }
    }
    this.imageBaseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          this.locData = locationData.body;
          this.locationDetailsForOneTime = locationData.body;
        },
        () => {
          this.locData = [];
        },
      );
    } else {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        data => {
          this.sessionStorageService.setItem('websitePartnerName', data.body.websitePartnerName);
          this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
          if (this.partnerName) {
            this.locData = data.body;
          }
          this.locationDetailsForOneTime = data.body;
        },
        () => {
          this.locData = [];
        },
      );
    }
    // if (this.isHome === 'false') {
    if (this.userDetailsWithAddress[0] && this.userDetailsWithAddress[0].customerNo && this.user.user._id) {
      const customernumber = this.userDetailsWithAddress[0].customerNo;
      this.resyncEnrollment.resyncEnrollment(customernumber, this.user.user._id).then();
    }
    // }
    this.getAlertsWithEnrolledProdIcons();
  }

  ngAfterViewInit() {
    this.scrollToSection();
  }

  goToTerms(service): void {
    if (service.productDetails) {
      const program = service.productDetails.program.programCode;
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      let isCombo = 0;
      if (service.productDetails.program.products.length > 1) {
        isCombo = 1;
      }
      if (iOS && isSafari) {
        newTab = window.open();
      }

      this.locationService.getLocationCode(service.address.serviceAddress.zipcode).subscribe(result => {
        const locationData = result.body;
        if (result.status === 200) {
          const zipcodeCheck =
            locationData.locationCodeId === service.address.serviceAddress.locationCodeId ? service.address.serviceAddress.zipcode : '0';
          this.productService
            .getTermsAndConditionsForProduct(
              'english',
              service.address.serviceAddress.state,
              zipcodeCheck,
              program,
              service.productDetails.contactType,
              isCombo,
              service.productDetails.coverageType,
              service.address.serviceAddress.locationCodeId,
            )
            .subscribe(
              response => {
                this.termsLink = `${environment.Image_API_Endpoint + environment.TERMS_SERVICE_ENDPOINT}/${
                  response.body.result && response.body.result.length ? response.body.result[0].pdfUrl : ''
                }`;
                if (iOS && isSafari) {
                  newTab.location.href = this.termsLink;
                } else {
                  window.open(this.termsLink, '_blank');
                }
              },
              error => {
                if (error.status === 404) {
                  this.showErrorModal('Error', 'File is not available, Please try later.');
                }
              },
            );
        }
      });
    } else {
      this.showErrorModal('Error', 'File is not available, Please try later.');
    }
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, alertIcon?: string, trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          alertIcon,
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  renewNowService(serviceDetail: any) {
    this.productService
      .getDefaultCodeForRenewalCheckout(
        serviceDetail.productDetails.marketingCodeId,
        serviceDetail.address.serviceAddress.locationCodeId,
        serviceDetail.productDetails.coverageType,
        serviceDetail.productDetails.contactType,
        serviceDetail.productDetails.programId,
        serviceDetail.address.customerNo,
        serviceDetail.evo_newmarketingcode,
        this.user.authHeader,
      )
      .subscribe(
        data => {
          const productData = data.body;
          this.renewNow(productData, serviceDetail);
        },
        () => {},
      );
  }

  renewNow(productData: any, serviceDetail: any) {
    serviceDetail = JSON.parse(JSON.stringify(serviceDetail));
    this.marketing_code_id = productData.marketingCodeId;
    const paymentmethod = 'Check';
    if (productData.defaultLocationCodeId === null) {
      productData.defaultLocationCodeId = serviceDetail.address.serviceAddress.locationCodeId
        ? serviceDetail.address.serviceAddress.locationCodeId
        : this.sessionStorageService.getItem('location_code');
    }
    this.locationService.getLocationsContent(productData.defaultLocationCodeId).subscribe(data => {
      const locationCode = data.body.locationCode;
      const frequencyVal = serviceDetail.billingFrequency === 'Monthly' ? 'Monthly' : 'Annually';
      this.showLoader = true;
      const getProductBillingType = this.crmProxyService.getProductBillTypes(
        productData.defaultLocationCodeId,
        productData.program.programId,
        this.marketing_code_id,
      );
      const getProducttaxAmount = this.crmProxyService.getProductsTaxCheckout(
        this.user.authHeader,
        serviceDetail.address.customerGuid,
        productData.marketingCodeId,
        productData.program.programId,
        locationCode,
        paymentmethod,
        frequencyVal,
      );

      forkJoin([getProductBillingType, getProducttaxAmount]).subscribe(
        productsData => {
          this.showLoader = true;
          this.billingData = productsData[0].body.data;
          this.taxData = productsData[1].body.data;

          serviceDetail.productDetails = productData;
          const billingType =
            this.billingData[0].operations.OnBill || (this.billingData[0].operations.OnBill && this.billingData[0].operations.OffBill)
              ? 'onbill'
              : 'offbill';
          if (billingType !== serviceDetail.billingType) {
            const strError = `We are unable to process your renewal at this time. Please contact Customer Service at <span class="app-txt">${environment.renewCustomerCareNumber}</span> for assistance. Thank you.`;
            this.showErrorModal("We're sorry", strError, '', '');
            this.showLoader = false;
            return;
          }
          this.showLoader = false;
          this.simpleModalService
            .addModal(
              RenewNowModalComponent,
              {
                title: '',
                message: '',
                serviceData: serviceDetail,
                billingData: this.billingData,
                taxData: this.taxData,
                marketing_code_id: this.marketing_code_id,
                description: 'Click continue to select your payment option',
                userDataToken: this.user,
                isFromCheckout: true,
                productData,
                services: this.services,
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: false,
              },
            )
            .subscribe(() => {
              /* if (isRenew) {
                            serviceDetail.renew = false;
                        } */
              this.showLoader = false;
            });
          this.showLoader = false;
        },
        () => {
          const errorTitle = "We're sorry, we are experiencing technical difficulties.";
          const strError = `Please try again. If you continue to experience issues, please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span> for assistance. Thank you.`;
          const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
          this.showErrorModal(errorTitle, strError, '', '', trackAPIGATracking);
          this.showLoader = false;
        },
      );
    });
  }

  checkBillingType(service) {
    if (
      service.billingType === 'onbill' ||
      !Object.prototype.hasOwnProperty.call(service, 'isAutoRenew') ||
      service.paymentMethod === 'Check' ||
      service.paymentMethod === 'eCheck'
    ) {
      return false;
    }
    return true;
  }

  allowOffbillRenew(service) {
    if (service.billingType === 'onbill') {
      return false;
    }
    return true;
  }

  isStateValid(service) {
    if (environment.hide_autorenew_states.includes(service.address.serviceAddress.state)) {
      return false;
    }
    return true;
  }

  changeEnrollment(index) {
    this.selectedEnrollAddIndexMOB = index;
  }

  showAlertModal(data?: any, done?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
        if (data.reloadPage) {
          // window.location.reload();
        }
      });
  }

  oneTimePayment(service) {
    const OtherGuid: String = this.services
      .filter(x => {
        if (x.renewalDate) {
          const renewalDateUTC: any = this.datePipe.transform(new Date(x.renewalDate), 'yyyy-MM-dd HH:mm:s', 'UTC');
          if (
            x.productId === service.productId &&
            Date.parse(renewalDateUTC) >= Date.parse(this.today) &&
            x.crmEnrollmentGuid !== service.crmEnrollmentGuid
          ) {
            return x.crmEnrollmentGuid;
          }
        }
      })
      .map(obj => obj.crmEnrollmentGuid);

    // if (OtherGuid.length > 0) {
    //     this.showErrorModal('Sorry for Inconvenience', `Please contact Customer Service at <span class="app-txt"> ${this.customer_service_number}</span>`, 'ok', 'fa fa-exclamation-triangle');
    // } else {
    this.simpleModalService.addModal(
      OneTimePaymentOracleComponent,
      {
        crmEnrollmentGuid: service.crmEnrollmentGuid,
        locationDetailsForOneTime: this.locationDetailsForOneTime,
        OtherGuid,
        userDetailsCheckout: this.user,
        isFromCheckout: true,
        inputProducts: this.services,
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: false,
      },
    );
    // }
  }

  isActionRequried(service) {
    let returnValue = false;
    if (!service) {
      return returnValue;
    }
    if (
      service.crmStatus === '2' &&
      service.renewNow === true &&
      !service.isAutoRenew &&
      service.productDetails.program.programCode !== 'LDDP' &&
      this.allowOffbillRenew(service) &&
      (!service.isAlreadyEnrolled || !this.renewedEnrollments.length)
    ) {
      // Renew
      returnValue = true;
    } else if (service.crmStatus === '6' && service.productDetails.program.programCode !== 'LDDP' && this.allowOffbillRenew(service)) {
      // Suspended
      returnValue = true;
    } /* else if (service.crmStatus === '2' && (service.crmEnrollmentGuid ? true : false) && this.showUpgrade(service.createdAtInCRM) && service.isUpgradeAuthorized && (renewalDate >= this.today)) {
            returnValue = true;
        }

        */
    return returnValue;
  }

  scrollToSection() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      'slow',
    );
  }

  getAlertsWithEnrolledProdIcons() {
    this.orderService.getProductIcons(this.user.user._id, this.user.authHeader).subscribe(
      results => {
        this.showLoader = true;
        if (results.status === 200) {
          const response = results.body.data;
          const addressData = [JSON.parse(JSON.stringify(this.userDetailsWithAddress.address))];
          const allCustomerNumbers = addressData.map(address => {
            if (![undefined, 'undefined', null, 'null', ''].includes(address.customerNo)) {
              return address.customerNo;
            }
            address.customerNo = 'no_custNo';
            return address.customerNo;
          });

          const enrolledProductsCustomerNumber = response.map(data => {
            if (![undefined, 'undefined', null, 'null', ''].includes(data)) {
              return Object.keys(data)[0];
            }
          });

          const missingCustomerNumbers = difference(allCustomerNumbers, enrolledProductsCustomerNumber);
          if (missingCustomerNumbers.length > 0) {
            missingCustomerNumbers.forEach(custNumber => {
              const obj = {};
              if (![undefined, 'undefined', null, 'null', ''].includes(custNumber)) {
                obj[custNumber] = {
                  icons: [],
                  isActionRequired: false,
                };
                const index = allCustomerNumbers.indexOf(custNumber);
                if (index !== -1) {
                  response.splice(index, 0, obj);
                }
              }
            });
          }

          const arr = [];
          allCustomerNumbers.forEach(custNumber => {
            const data = find(response, custNumber);
            data[custNumber].icons = Array.from(new Set(data[custNumber].icons)).reverse();
            data[custNumber].icons = data[custNumber].icons.filter(value => {
              if (this.services.some(obj => obj.productDetails.programIconUrl === value)) {
                return true;
              }
              return false;
            });
            arr.push(data);
          });
          this.propertiesInfo = arr;
          this.showLoader = false;
        }
      },
      () => {
        this.showLoader = false;
        this.propertiesInfo = [];
      },
    );
  }

  showAuthenticationModal(payload: any, existingCodes: any, title = '', modalFor = 0, emailpayload = {}) {
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data: '',
          modalFor,
          existing: existingCodes,
          deleteAddressPayload: emailpayload,
          address: payload,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }

  sortServicesBasedOnQueryParams(queryParam) {
    let statusCode: any;
    if (queryParam === 'renewal') {
      statusCode = '2';
    } else if (queryParam === 'reactivate') {
      statusCode = '6';
    }
    this.services.sort((ele1, ele2) => {
      if (ele1.crmStatus === statusCode && ele2.crmStatus !== statusCode) {
        return -1; // ele1 comes before ele2
      }
      if (ele1.crmStatus !== statusCode && ele2.crmStatus === statusCode) {
        return 1; // ele2 comes before ele1
      }
      return 0; // no change in order
    });
  }
}
